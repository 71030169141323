<template>
  <div>
    <load-profile></load-profile>
    <section class="invoice-preview-wrapper">
      <b-row v-if="data.id" class="invoice-preview">
        <b-col cols="12" md="8" xl="9" class="pr-md-0">
          <b-card no-body class="invoice-preview-card">
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo ml-0">
                      {{ $t("breadcrumb.salesOrder") }}
                    </h3>
                  </div>
                  <p class="card-text mb-25">
                    {{ data.billToKhmerName }}
                  </p>
                  <p class="card-text mb-0">
                    {{ data.billToKhmerAddress }}
                  </p>
                </div>

                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    <span class="invoice-number">#{{ data.no }}</span>
                  </h4>
                </div>
              </div>
            </b-card-body>

            <hr class="invoice-spacing" />

            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" xl="6" class="p-0">
                  <h6>{{ $t("field.invoiceTo") }}:</h6>
                  <h6 class="mb-25">
                    {{ trans(data, "sellToCustomerName", $i18n.loacle) }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ data.sellToCustomerNo }}
                  </p>
                  <p class="card-text mb-25">
                    {{ trans(data, "sellToAddress", $i18n.loacle) }}
                  </p>
                  <p class="card-text mb-25">
                    {{ data.sellToContactNo }}
                  </p>

                  <h6 class="mt-2">{{ $t("field.shipment") }}:</h6>
                  <h6 class="mb-25">
                    {{ trans(data, "shipToName", $i18n.loacle) }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ trans(data, "shipToAddress", $i18n.loacle) }}
                  </p>
                </b-col>

                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6>{{ $t("field.information") }}:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">{{ $t("field.createdDate") }} :</td>
                          <td>
                            <span class="font-weight-bold">
                              {{ data.createdAt | formatDate("LL") }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.state") }} :</td>
                          <td>
                            <span :class="data.status">
                              {{ data.status }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t("field.expectDeliveryDate") }} :
                          </td>
                          <td>
                            <span class="font-weight-bold">
                              {{ data.shipmentDate | formatDate("LL") }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t("field.paymentMethod") }} :
                          </td>
                          <td>
                            <span class="font-weight-bold">
                              {{
                                data.paymentMethod ? data.paymentMethod : "-"
                              }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">
                            {{ $t("field.orderReference") }} :
                          </td>
                          <td>
                            <span class="font-weight-bold">
                              {{
                                data.externalDocumentNo
                                  ? data.externalDocumentNo
                                  : "-"
                              }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <div class="order-summary">
              <n-table
                class="px-2 order-summary-table"
                ref="table"
                :fields="fields"
                :items="displayItems"
              >
                <template #cell(productName)="data">
                  {{ trans(data.item, "productName", $i18n.loacle) }}
                  <small class="d-block text-primary">
                    {{ data.item.no }}
                  </small>
                  <small
                    v-for="(description, i) in getLineDiscountDescription(
                      data.item
                    )"
                    :key="`description-${i}`"
                    class="d-block"
                  >
                    {{ trans(description, "productName", $i18n.loacle) }}
                  </small>
                </template>
                <template #cell(quantity)="data">
                  {{ data.item.quantity }} {{ data.item.unitOfMeasureCode }}
                </template>
                <template #cell(discount)="data">
                  <span class="text-danger">
                    {{ getLineDiscount(data.item) | currency }}
                  </span>
                </template>
                <template #cell(lineAmount)="data">
                  <span
                    :class="{
                      'text-danger': getLineTotalAfterDiscount(data.item) < 0,
                    }"
                  >
                    {{ getLineTotalAfterDiscount(data.item) | currency }}
                  </span>
                </template>
              </n-table>

              <b-card-body class="invoice-padding">
                <b-row>
                  <b-col cols="12" class="d-flex justify-content-end">
                    <div class="invoice-total-wrapper">
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("field.total") }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ data.amount | currency }}
                        </p>
                      </div>
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("field.vat") }}:
                        </p>
                        <p class="invoice-total-amount">
                          {{ vat | currency }}
                        </p>
                      </div>
                      <hr class="my-50" />
                      <div class="invoice-total-item">
                        <p class="invoice-total-title">
                          {{ $t("field.totalWithVat") }}:
                        </p>
                        <p class="invoice-total-amount text-primary">
                          {{ data.amountIncludingVat | currency }}
                        </p>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-card-body>
            </div>
          </b-card>
        </b-col>

        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <n-button-loading
              :loading="loading"
              :disabled="!$can('create', resource)"
              :variant="
                !$can('create', resource)
                  ? 'secondary'
                  : data.status == 'Open' || data.status == 'Released'
                  ? 'danger'
                  : 'primary'
              "
              class="mb-75"
              block
              @click="reorder"
            >
              {{
                data.status == "Open" || data.status == "Released"
                  ? $t("button.cancelAndReorder")
                  : $t("button.reorder")
              }}
            </n-button-loading>
            <n-button-loading
              :loading="loading"
              :disabled="
                !$can('create', resource) ||
                (data.status != 'Open' && data.status != 'Released')
              "
              :variant="
                !$can('create', resource) ||
                (data.status != 'Open' && data.status != 'Released')
                  ? 'secondary'
                  : 'danger'
              "
              class="mb-75"
              block
              @click="cancel"
            >
              {{ $t("button.cancelOrder") }}
            </n-button-loading>
            <hr class="my-1" />
            <n-button-loading
              :loading="loading"
              :disabled="
                (!$can('create', resource) && !$can('update', resource)) ||
                data.status != 'Released'
              "
              :variant="
                (!$can('create', resource) && !$can('update', resource)) ||
                data.status != 'Released'
                  ? 'secondary'
                  : 'success'
              "
              class="mb-75"
              block
              @click="post"
              v-if="data.status != 'Posted'"
            >
              {{ $t("button.postShipAndInvoice") }}
            </n-button-loading>
            <n-button-loading
              :disabled="
                !$can('read', 'agent-sales-invoice') ||
                data.status != 'Posted' ||
                !data.salesInvoiceId
              "
              :variant="
                !$can('read', 'agent-sales-invoice') ||
                data.status != 'Posted' ||
                !data.salesInvoiceId
                  ? 'secondary'
                  : 'primary'
              "
              class="mb-75"
              block
              v-if="data.status == 'Posted'"
              @click="
                $router.push({
                  name: 'view-sales-invoice',
                  params: { id: data.salesInvoiceId },
                })
              "
            >
              {{ $t("button.viewInvoice") }}
            </n-button-loading>
            <download-pdf-button
              :disabled="data.status != 'Posted' || !data.salesInvoiceId"
              :variant="
                data.status != 'Posted' || !data.salesInvoiceId
                  ? 'secondary'
                  : 'success'
              "
              :repository="SalesInvoiceRepository"
              :id="data.salesInvoiceId"
              :file-name="
                $t('breadcrumb.invoice') + ' - ' + data.salesInvoiceNo
              "
              class="mb-75"
              block
              v-if="data.status == 'Posted'"
            >
              <i class="las la-file-invoice-dollar"></i>
              {{ $t("button.downloadInvoice") }}
            </download-pdf-button>
            <download-pdf-button
              variant="success"
              :repository="
                !data.salesShipmentId || data.status != 'Posted'
                  ? SalesOrderRepository
                  : SalesShipmentRepository
              "
              :id="
                !data.salesShipmentId || data.status != 'Posted'
                  ? data.id
                  : data.salesShipmentId
              "
              :file-name="
                $t('field.deliveryNote') +
                ' - ' +
                (data.salesShipmentNo ? data.salesShipmentNo : data.no)
              "
              block
            >
              <i class="las la-truck"></i>
              {{ $t("button.downloadDeliveryNote") }}
            </download-pdf-button>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BBadge, BTr, BTh } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import NTable from "@/components/NTable";
import tableFields from "./viewTableFields";
import DownloadPdfButton from "@/components/DownloadPdfButton";

const SalesOrderRepository = Repository.get("salesOrder");
const SalesOrderActionRepository = Repository.get("salesOrderAction");
const SalesInvoiceRepository = Repository.get("salesInvoice");
const SalesShipmentRepository = Repository.get("salesShipment");

const salesOrderType = 1;

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BBadge,
    BTr,
    BTh,

    NTable,
    NButtonLoading,
    DownloadPdfButton,
  },
  computed: {
    vat() {
      if (!this.data.id) {
        return 0;
      }

      return (this.data.amountIncludingVat - this.data.amount).toFixed(2);
    },
  },
  data() {
    return {
      loading: false,
      data: {},
      items: [],
      displayItems: [],
      SalesOrderRepository,
      SalesInvoiceRepository,
      SalesShipmentRepository,
    };
  },
  created() {
    this.show();
  },
  methods: {
    getLineTotalAfterDiscount(item) {
      if (item.lineAmount == 0 && item.unitPrice > 0) {
        return this.getLineDiscount(item) + item.quantity * item.unitPrice;
      }
      return item.lineAmount + this.getLineDiscount(item);
    },
    getLineDiscount(item) {
      let totalDiscount = 0;
      if (item.lineAmount == 0 && item.unitPrice > 0) {
        totalDiscount = item.quantity * item.unitPrice * -1;
      }
      this.items.forEach((element) => {
        if (element.attachedToLineNo == item.lineNo) {
          totalDiscount += element.lineAmount;
        }
      });
      return totalDiscount;
    },
    getLineDiscountDescription(item) {
      let descriptions = [];
      this.items.forEach((element) => {
        if (element.attachedToLineNo == item.lineNo) {
          descriptions.push(element);
        }
      });
      return descriptions;
    },
    show() {
      this.loading = true;
      SalesOrderRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            if (data.documentType != "Order") {
              this.$router.push({ name: "error-404" });
            }
            this.data = { ...data };
            this.items = [...data.lines];
            const displayItems = [];
            this.items.forEach((element) => {
              if (element.attachedToLineNo == 0) {
                displayItems.push(element);
              }
            });
            this.displayItems = [...displayItems];
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
    post() {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.postShipAndInvoice"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          okTitle: this.$t("button.postShipAndInvoice"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            SalesOrderActionRepository.post(this.data.id)
              .then((response) => {
                const data = response?.data?.data;
                if (data) {
                  this.data = { ...data };
                  this.items = [...data.lines];
                }
                this.loading = false;
              })
              .catch(() => (this.loading = false));
          }
        });
    },
    cancel() {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.cancelOrder"), {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: this.$t("button.cancelOrder"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            SalesOrderRepository.cancel({
              id: this.data.id,
              type: salesOrderType,
            })
              .then((response) => {
                this.loading = false;
                this.show();
              })
              .catch(() => (this.loading = false));
          }
        });
    },
    reorder() {
      const title =
        this.data.status == "Open" || this.data.status == "Released"
          ? this.$t("alert.cancelAndReorder")
          : this.$t("alert.reorder");
      const btnLabel =
        this.data.status == "Open" || this.data.status == "Released"
          ? this.$t("button.cancelAndReorder")
          : this.$t("button.reorder");
      this.$bvModal
        .msgBoxConfirm(title, {
          title: this.$t("alert.areYouSure"),
          size: "sm",
          buttonSize: "sm",
          okVariant:
            this.data.status == "Open" || this.data.status == "Released"
              ? "danger"
              : "primary",
          okTitle: btnLabel,
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;
            SalesOrderRepository.reorder({
              id: this.data.id,
              type: salesOrderType,
            })
              .then((response) => {
                this.$router.push({
                  name: "create-sales-order",
                });
                this.loading = false;
              })
              .catch(() => (this.loading = false));
          }
        });
    },
  },
  setup() {
    const fields = [...tableFields];

    const resource = "agent-sales-order";
    const route = "sales-order";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
